<template>
	<div
		:class="[
			'absolute transform -translate-x-1/2 -translate-y-1/2 -top-2 text-gray-300',
			position
		]"
	>
		<div class="quarter-circle-right w-1 h-1 border-b border-gray-300 absolute z-50" />
		<div class="quarter-circle-left w-1 h-1 border-b border-gray-300 absolute z-50" />
		<div class="absolute h-full border-t-4 border-white z-40 blank-box rounded" />
		<div class="absolute h-full border-t-4 border-white z-40 blank-box-2 rounded" />

		<svg
			width="12"
			height="6"
			viewBox="0 0 22 11"
			fill="white"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.17157 2.82843L-1.90735e-06 11L0.392857 11L21.7054 11L22 11L13.8284 2.82843C12.4951 1.49509 11.8284 0.828428 11 0.828428C10.1716 0.828428 9.50491 1.49509 8.17158 2.82842L8.17157 2.82843Z"
				fill="white"
				stroke="currentColor"
			/>
		</svg>
	</div>
</template>

<script async>
export default {
	props: {
		position: {
			type: String,
			default: 'left-1/2'
		}
	}
}
</script>

<style scoped>
.quarter-circle-right {
	border-bottom-left-radius:100%;
	left:10px;
	top:2.5px;
}
.quarter-circle-left {
	border-bottom-right-radius:100%;
	right:10px;
	top:2.5px;
}
.blank-box {
	width:13px;
	left:0.1px;
	top:5.1px;
}
.blank-box-2 {
	width:13px;
	top:5.1px;
	right:0.1px;
}
</style>
