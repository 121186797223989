<template>
	<div
		v-click-outside="closeDropDown"
		class="inline-block text-left"
		@keyup.esc="closeDropDown"
		@keydown.tab="closeDropDown"
		@keydown.down.prevent="toggleDown"
		@keydown.up.prevent="toggleUp"
		@keydown.enter="navigateToActiveLink"
		@mouseover="hoverOpen"
		@mouseleave="hoverClose"
	>
		<div
			id="trigger"
			ref="trigger"
		>
			<slot
				name="button"
				:clicked="handleClick"
			>
				<button
					id="dropdown-button"
					type="button"
					:class="buttonClasses"
					aria-expanded="true"
					aria-haspopup="true"
					@click="handleClick"
				>
					{{ title }}
					<ArrowDown
						:class="[
							'transition-all ease-in h-2.5 w-2.5 ml-2 mt-1',
							{ 'transform rotate-180': expanded }
						]"
					/>
				</button>
			</slot>
		</div>

		<div
			id="content"
			ref="content"
			class="static"
		>
			<Transition :name="transitionClass">
				<div
					v-show="expanded"
					:class="[
						expanded ? 'transition ease-out duration-100 transform opacity-100 scale-100' : 'transition ease-in duration-75 transform opacity-0 scale-95',
						contentWidth,
						contentClasses
					]"
					class="absolute z-30 py-1 mt-2.5 text-sm bg-white rounded-md border border-gray-300 shadow-lg md:text-base"
					:style="{ [xDirection]: xOffset + 'px' }"
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="menu-button"
				>
					<div class="relative grid grid-cols-1">
						<DropdownArrow
							v-if="showTopArrow"
							class="z-10"
							:position="topArrowPosition"
						/>
						<div
							v-if="showCloseButton"
							class="flex justify-end w-full"
						>
							<button
								class="mx-4 mt-2"
								@click="closeDropDown"
							>
								<CloseIcon />
							</button>
						</div>
						<div
							v-for="(item, index) in menuItems"
							:id="`${buttonId}-menu-popout-${index}`"
							:key="`${buttonId}-menu-popout-${index}`"
							role="none"
							class="flex content-center w-full text-sm md:text-base"
							:aria-live="ariaLive(index)"
						>
							<a
								v-if="isExternalLink(item)"
								:id="`${buttonId}-menu-item-${index}`"
								:ref="`${buttonId}-menu-item-${index}`"
								:key="`${buttonId}-menu-item-${index}`"
								:href="item.url"
								:class="[
									item.class && item.class.length ? item.class : 'px-3 py-2',
									selectedMenuItemStyle(index),
									'block w-full text-left text-black focus:bg-gray-200 focus:shadow-inner hover:bg-gray-200 transition-all duration-100 ease-in-out'
								]"
								target="_blank"
								role="menuitem"
								tabindex="0"
								@click="handleClick"
							>
								{{ item.text }}
							</a>

							<router-link
								v-if="isRouterLink(item)"
								:id="`${buttonId}-menu-item-${index}`"
								:ref="`${buttonId}-menu-item-${index}`"
								:key="`${buttonId}-menu-item-${index}`"
								:to="item.to"
								:class="[
									item.class && item.class.length ? item.class : 'px-3 py-2',
									selectedMenuItemStyle(index),
									'block w-full text-left text-black focus:bg-gray-200 focus:shadow-inner hover:bg-gray-200 transition-all duration-100 ease-in-out'
								]"
								role="menuitem"
								tabindex="0"
								@click.native="handleClick"
							>
								{{ item.text }}
							</router-link>

							<label
								v-if="isCheckbox(item)"
								class="relative flex items-center flex-none px-3 pb-2 mb-0 text-sm input-element md:text-base"
								:class="{ 'pt-2': index === 0 }"
							>
								<input
									:id="`${buttonId}-menu-item-${index}`"
									:ref="`${buttonId}-menu-item-${index}`"
									type="checkbox"
									:class="[
										selectedMenuItemStyle(index),
										'w-5 h-5 mr-3 text-green-500 border border-gray-300 rounded-sm appearance-none focus:ring focus:ring-green-300 hover:bg-gray-200 transition-all duration-100 ease-in-out'
									]"
									role="menuitem"
									tabindex="0"
									:checked="activeCheckboxes.includes(item.value)"
									@click="handleChecked(item.value)"
								>

								{{ item.text }}
							</label>

							<button
								v-if="isButton(item)"
								:id="`${buttonId}-menu-item-${index}`"
								:ref="`${buttonId}-menu-item-${index}`"
								:key="`${buttonId}-menu-item-${index}`"
								:class="[
									item.class && item.class.length ? item.class : 'px-3 py-2',
									selectedMenuItemStyle(index),
									'w-full text-left text-black hover:bg-gray-200 transition-all duration-100 ease-in-out'
								]"
								role="menuitem"
								tabindex="0"
								@click="handleClick(item.value)"
							>
								{{ item.text }}
							</button>
							<CartDropdownCard
								v-if="isCartCard(item)"
								:cart="item"
								@close-drop-down="closeDropDown"
							/>
						</div>
					</div>
				</div>
			</Transition>
		</div>
	</div>
</template>

<script async>
import ArrowDown from '@/components/icons/ArrowDown.vue'
import DropdownArrow from '@/components/icons/DropdownArrow.vue'
import { BOTTOM, LEFT, RIGHT, TOP } from '@/constants/positions.js'

const VERTICAL_PADDING_PX = 8
const BOTTOM_OF_PAGE_SPACING = 20
export default {
	components: {
		DropdownArrow,
		ArrowDown,
		CartDropdownCard: () => import('@/components/cart/CartDropdownCard.vue'),
		CloseIcon: () => import('@/components/icons/CloseIcon.vue')
	},
	props: {
		buttonId: {
			type: String,
			required: true
		},
		menuItems: {
			type: Array,
			required: true
		},
		title: {
			type: String,
			default: 'Dropdown'
		},
		resetCheckboxes: {
			type: Boolean,
			default: false
		},
		parentReferenceLevel: {
			type: Number,
			default: 1
		},
		parentReferenceName: {
			type: String,
			default: ''
		},
		initialActiveItems: {
			type: Array,
			default: () => []
		},
		contentWidth: {
			type: String,
			default: 'w-auto'
		},
		contentClasses: {
			type: String,
			default: ''
		},
		transitionClass: {
			type: String,
			default: 'slide-down'
		},
		forceOpenPosition: {
			type: String,
			default: '',
			validator: value => [
				LEFT,
				RIGHT,
				''
			].includes(value)
		},
		buttonClasses: {
			type: Array,
			default() {
				return [ 'inline-flex justify-center w-full px-4 py-2 font-bold text-black bg-white border border-gray-300 rounded-md shadow-sm' ]
			}
		},
		showTopArrow: {
			type: Boolean,
			default: false
		},
		centerTopArrow: {
			type: Boolean,
			default: false
		},
		xOffsetOverride: {
			type: Number,
			default: null
		},
		openOnHover: {
			type: Boolean,
			default: false
		},
		showCloseButton: {
			type: Boolean,
			default: false
		}
	},
	emits: [ 'menu-clicked', 'expanded' ],
	data() {
		return {
			expanded: false,
			activeCheckboxes: [],
			triggerHeightInPx: 0,
			xPositionStart: RIGHT,
			yPosition: BOTTOM,
			yOffset: 0,
			xOffset: 0,
			activeIndex: -1
		}
	},
	computed: {
		topArrowPosition() {
			if (this.centerTopArrow) {
				return 'left-1/2'
			}
			if (this.xPositionStart === RIGHT) {
				return 'right-2'
			} else {
				return 'left-5'
			}
		},
		xDirection() {
			return this.xPositionStart === RIGHT ? 'right' : 'left'
		},
		yOffsetStyle() {
			const totalOffset = this.yOffset + this.triggerHeightInPx
			return this.yPosition === BOTTOM ? '' : `margin-top: -${totalOffset}px`
		},
		parentRef() {
			switch (this.parentReferenceLevel) {
				case 1:
					return this.$parent.$refs[this.parentReferenceName]
				case 2:
					return this.$parent.$parent.$refs[this.parentReferenceName]
				default:
					return false
			}
		},
		parentIsXScrollable() {
			return this.parentRef?.classList.contains('overflow-x-auto') || this.parentRef?.classList.contains('overflow-x-scroll')
		}
	},
	watch: {
		resetCheckboxes() {
			if (this.resetCheckboxes === true) this.clearAllCheckboxes()
		},
		expanded() {
			if (this.expanded) {
				this.$nextTick(() => this.calculatePosition())
			}
			this.$nextTick(() => this.$emit('expanded', this.expanded, this.buttonId))
		},
		initialActiveItems: {
			immediate: true,
			handler: function() {
				this.initialActiveItems.forEach(checkbox => {
					if (this.activeCheckboxes.includes(checkbox)) return
					this.activeCheckboxes.push(checkbox)
				})
			}
		},
		forceOpenPosition: {
			immediate: true,
			handler: function(newValue) {
				if (newValue.length) {
					this.xPositionStart = newValue
				}
			}
		}
	},
	mounted() {
		this.calculatePosition()
	},
	methods: {
		clearAllCheckboxes() {
			for (const index in this.menuItems) {
				document.getElementById(`${this.buttonId}-menu-item-${index}`).checked = false
			}
		},
		isExternalLink(item) {
			return item?.type === 'link'
		},
		isRouterLink(item) {
			return item?.type === 'router-link'
		},
		isCheckbox(item) {
			return item?.type === 'checkbox'
		},
		isButton(item) {
			return item?.type === 'button'
		},
		isCartCard(item) {
			return item?.type === 'cartCard'
		},
		getPosition(el) {
			const height = el.clientHeight
			const positionRight = el.getBoundingClientRect().right
			const positionLeft = el.getBoundingClientRect().left
			const positionBottom = el.getBoundingClientRect().bottom
			return {
				height,
				positionRight,
				positionLeft,
				positionBottom
			}
		},
		checkTriggerOverflow (containerWidth, elPositionRight, elPositionLeft) {
			const overflowsRight = elPositionRight > containerWidth
			const overflowsLeft = Math.sign(elPositionLeft) === -1
			return {
				overflowsRight,
				overflowsLeft
			}
		},
		getXPositionStart(el, trigger, content, contentWidth, containerWidth) {
			// if trigger DOES overflow, decide which way to open dropdown content (RIGHT === from Right to Left, LEFT === from Left to Right)
			if (trigger.overflowsRight) {
				this.xPositionStart = RIGHT
			} else {
				this.xPositionStart = LEFT
			}
			if (trigger.overflowsLeft) {
				this.xPositionStart = LEFT
			}
			// check content overflow
			content.overflowsLeft = el.positionLeft + contentWidth > containerWidth
			content.overflowsRight = el.positionRight > (containerWidth - contentWidth)
			// if trigger DOESN'T overflow, decide which way to open (RIGHT === from Right to Left, LEFT === from Left to Right)
			if (content.overflowsLeft) {
				if (content.overflowsRight) {
					this.xPositionStart = RIGHT
				} else {
					this.xPositionStart = LEFT
				}
			}
			// if forceOpenPosition is set, use it
			if (this.forceOpenPosition.length) {
				this.xPositionStart = this.forceOpenPosition
			}
		},
		calculatePosition() {
			let el = {}
			let trigger = {}
			const content = {}
			let containerWidth = 0
			const browserWidth = document.querySelector('body').getBoundingClientRect().width
			const browserHeight = document.querySelector('body').getBoundingClientRect().height
			const parentRefWidth = this.parentRef?.offsetWidth
			const contentWidth = this.$refs.content.children[0].offsetWidth
			this.triggerHeightInPx = this.$refs.trigger.offsetHeight
			// check if the filter bar srolls horizontally and set size of container to get our bounds to calculate position
			if (this.parentRef !== false && this.parentRef !== undefined) { // check if parentRef prop was passed in to this component
				if (this.parentIsXScrollable) { // use full browser width as bounds for dropdown to calculate opening position
					containerWidth = browserWidth
				} else { // use the parent container as bounds dropdown to calculate position opening position
					containerWidth = parentRefWidth
				}
			} else { // default case for the container bounds that dropdown uses to calculate opening position
				containerWidth = browserWidth
			}
			el = { ...this.getPosition(this.$refs.content) }
			trigger = { ...this.checkTriggerOverflow(containerWidth, el.positionRight, el.positionLeft) }
			if (this.parentIsXScrollable && this.expanded) { // if scrollable, set x-axis scroll position
				// move scroll position if overflowing before setting position of dropdown content
				if (trigger.overflowsRight) {
					const newTriggerOffset = el.positionRight - containerWidth
					this.parentRef.scrollLeft += ((newTriggerOffset) + 20)
				}
				if (trigger.overflowsLeft) {
					this.parentRef.scrollLeft -= (Math.abs(el.positionLeft) + 20)
				}
			}
			// get updated element position
			el = { ...this.getPosition(this.$refs.content) }
			// calculate which x-direction the dropdown should open (LEFT or RIGHT)
			this.getXPositionStart(el, trigger, content, contentWidth, containerWidth)
			// get yPosition to determine if the dropdown should expand up or down (top or bottom)
			this.yPosition = el.positionBottom > browserHeight - BOTTOM_OF_PAGE_SPACING ? TOP : BOTTOM
			this.yOffset = this.yPosition === BOTTOM ? 0 : el.height + VERTICAL_PADDING_PX

			if (this.xOffsetOverride) { // if xOffsetOverride prop was passed in, use it
				this.xOffset = this.xOffsetOverride
			} else { // default case for xOffset
				this.xOffset = this.xPositionStart === RIGHT ? (containerWidth - el.positionRight) : el.positionLeft
			}
		},
		handleClick(value) {
			if (typeof value === 'string') this.$emit('menu-clicked', value)
			this.expanded = !this.expanded
		},
		handleChecked(value) {
			if (typeof value === 'string') {
				const index = this.activeCheckboxes.indexOf(value)
				if (index > -1) {
					this.activeCheckboxes.splice(index, 1)
				} else {
					this.activeCheckboxes.push(value)
				}
				this.checkboxId = value
				this.$emit('menu-clicked', value, this.activeCheckboxes)
			}
		},
		closeDropDown() {
			this.expanded = false
			this.activeIndex = -1
		},
		toggleDown() {
			if (this.activeIndex < this.menuItems.length - 1 && this.expanded) {
				this.activeIndex++
			} else if (this.activeIndex === this.menuItems.length - 1 && this.expanded) {
				this.activeIndex = 0
			}
		},
		toggleUp() {
			if (this.activeIndex > 0 && this.expanded) {
				this.activeIndex--
			} else if (this.activeIndex === 0 && this.expanded) {
				this.activeIndex = this.menuItems.length - 1
			}
		},
		navigateToActiveLink() {
			if (this.isExternalLink(this.menuItems[this.activeIndex])) {
				location.assign(this.menuItems[this.activeIndex].url)
			} else if (this.isRouterLink(this.menuItems[this.activeIndex])) {
				this.$router.push(this.menuItems[this.activeIndex].to)
			} else if (this.isCheckbox(this.menuItems[this.activeIndex])) {
				this.handleChecked(this.menuItems[this.activeIndex].value)
				this.expanded = !this.expanded
			} else if (this.isButton(this.menuItems[this.activeIndex])) {
				this.handleClick(this.menuItems[this.activeIndex].value)
			}
		},
		selectedMenuItemStyle(index) {
			if (index === this.activeIndex && this.isCheckbox(this.menuItems[this.activeIndex]))			 {
				return 'ring ring-green-300'
			}
			return index === this.activeIndex ? 'shadow-inner bg-gray-200' : ''
		},
		ariaLive (index) {
			return index === this.activeIndex ? 'assertive' : 'off'
		},
		hoverOpen() {
			setTimeout(() => {
				if (this.openOnHover) {
					this.expanded = true
				}
			}, 10)
		},
		hoverClose() {
			if (this.openOnHover) {
				this.closeDropDown()
			}
		}
	}
}
</script>
<style>
	.slide-up-enter-active,
	.slide-up-leave-active {
		transition: all .3s ease-in-out;
	}
	.slide-up-enter,
	.slide-up-leave-to {
		transform: translateY(30px);
		opacity: 0;
	}
	.slide-down-enter-active,
	.slide-down-leave-active {
		transition: all .3s ease-in-out;
	}
	.slide-down-enter,
	.slide-down-leave-to {
		transform: translateY(-30px);
		opacity: 0;
	}
</style>
